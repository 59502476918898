import { Grid, MenuItem, MenuList, Typography } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import BookingForm from '../BookingForm';
import Voucher from '../Voucher';
import styles from './styles';

const AddEditBooking = () => {
    const classes = styles();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        document.getElementById(location.hash.slice(1))?.scrollIntoView({ behavior: 'smooth' });
    }, [location.hash]);

    const menuItems = useMemo(
        () => [
            {
                className: 'guest-details',
                label: 'Guest Details',
                sectionId: '#guest-details',
            },
            {
                className: 'trip-details',
                label: 'Trip Details',
                sectionId: '#trip-details',
            },
            {
                className: 'estimates-charges',
                label: 'Estimates and Charges',
                sectionId: '#estimates-charges',
            },
            {
                className: 'payment-details',
                label: 'Payment Details',
                sectionId: '#payment-details',
            },
            {
                className: 'additional-notes',
                label: 'Additional Notes',
                sectionId: '#additional-notes',
            },
        ],
        [],
    );

    function handleMenuItemClick(id: string) {
        history.push(`${location.pathname}${location.search}#${id}`);
    }

    return (
        <Grid container justify="space-between">
            <Grid container item xs={1} direction="column" className={classes.navContainer}>
                <MenuList disablePadding className={classes.menuList}>
                    {menuItems?.map(({ className, label, sectionId }) => (
                        <MenuItem
                            disableRipple
                            to={sectionId}
                            key={className}
                            component={Link}
                            className={`${className} ${classes.menuItem}`}
                            onClick={() => handleMenuItemClick(className)}>
                            <Typography
                                className={`${classes.menuItemLabel} ${
                                    location.hash.slice(1) === className ? 'selectedMenu' : ''
                                }`}
                                color="textPrimary">
                                {label}
                            </Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Grid>
            <Grid item xs={5}>
                <BookingForm />
            </Grid>
            <Grid item xs={3}>
                <Voucher />
            </Grid>
        </Grid>
    );
};

export default AddEditBooking;
