import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    searchContainer: theme.mixins.searchContainer,
    boldTableCellText: theme.mixins.boldTableCellText,
    normalTableCellText: theme.mixins.normalTableCellText,
    table: {
        marginTop: 22,
        boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.12)',
    },
    countDetails: theme.mixins.countDetails,
    emptyDiv: {
        width: 200,
    },
    filtersNewButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '25px',
    },
    emptyTableCell: theme.mixins.emptyTableCell,
}));

export default styles;
