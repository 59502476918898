/**
 * @function storage
 * @description Wrapper function for localStorage to wrap the complexity.
 * @returns {{ get: (k: string), set(k: string, v: string) }} The API will consist of get(k) to fetch items from the storage and set(k, v) to set them.
 */
const storage = () => ({
    get(k: string) {
        try {
            return JSON.parse(localStorage.getItem(k) ?? '');
        } catch (e) {
            return null;
        }
    },
    set(k: string, v: string) {
        localStorage.setItem(k, JSON.stringify(v));
    },
});

export default storage;
