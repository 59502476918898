import Autocomplete from '@Components/Autocomplete';
import DatePicker from '@Components/DatePicker';
import NumberField from '@Components/NumberField';
import TextField from '@Components/TextField';
import TimePicker from '@Components/TimePicker';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { AddEditBookingFormData } from '@Store/booking/types';
import { BOOKING_TYPE, bookingFormStateType } from '@Store/booking/types';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';

import formSchema from './formSchema';
import styles from './styles';

const BookingForm = () => {
    const classes = styles();
    const history = useHistory();
    const location = useLocation<bookingFormStateType>();
    const bookingType = location?.state?.type;

    const { register, handleSubmit, errors, control } = useForm<AddEditBookingFormData>({
        resolver: yupResolver(formSchema),
        defaultValues: {
            agency: {
                name: '',
                contactNumber: '',
                bookedBy: '',
                bookingReference: '',
            },
            company: {
                name: '',
                contactNumber: '',
                bookedBy: '',
                bookingReference: '',
            },
            customer: {
                contactNumber: '',
                name: '',
                alternateContact: '',
                email: '',
                city: '',
            },
            trip: {
                start: {
                    date: '',
                    time: '',
                },
                end: {
                    date: '',
                    time: '',
                },
                pickUp: '',
                dropOff: '',
                bookingType: null,
                numberPax: 1,
                vehicleType: null,
                itinerary: '',
            },
            estimate: {
                fare: 0,
                totalDistance: 0,
                extraFarePerKm: 0,
                totalTime: 0,
                extraFarePerHr: 0,
            },
            otherCharge: null,
            additional: {
                payment: [
                    {
                        date: '',
                        amount: 0,
                        method: null,
                        credit: false,
                    },
                ],
                notes: '',
            },
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'payments',
    });

    const onSubmit = (data: unknown) => console.log(data);

    const handleCancel = () => {
        history.replace('/bookings');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
                {bookingType === BOOKING_TYPE.AGENT ? (
                    <>
                        <Grid container spacing={3} className="page-section" id="guest-details">
                            <Grid item xs={6}>
                                <TextField
                                    name="agency.name"
                                    label="Agency Name *"
                                    register={register}
                                    error={errors.agency?.name?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="agency.contactNumber"
                                    label="Agency Contact Number *"
                                    register={register}
                                    error={errors.agency?.contactNumber?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="agency.bookedBy"
                                    label="Booked By"
                                    register={register}
                                    error={errors.agency?.bookedBy?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="agency.bookingReference"
                                    label="Booking Reference"
                                    register={register}
                                    error={errors.agency?.bookingReference?.message}
                                />
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                    </>
                ) : null}
                {bookingType === BOOKING_TYPE.CORPORATE ? (
                    <>
                        <Grid container spacing={3} className="page-section" id="guest-details">
                            <Grid item xs={6}>
                                <TextField
                                    name="company.name"
                                    label="Company Name"
                                    register={register}
                                    error={errors.company?.name?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="company.contactNumber"
                                    label="Company Contact Number *"
                                    register={register}
                                    error={errors.company?.contactNumber?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="company.bookedBy"
                                    label="Booked By"
                                    register={register}
                                    error={errors.company?.bookedBy?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="company.bookingReference"
                                    label="Booking Reference"
                                    register={register}
                                    error={errors.company?.bookingReference?.message}
                                />
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                    </>
                ) : null}
                <Grid container spacing={3} className="page-section" id="guest-details">
                    <Grid item xs={6}>
                        <TextField
                            name="customer.contactNumber"
                            label="Customer Contact Number*"
                            register={register}
                            error={errors.customer?.contactNumber?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="customer.name"
                            label="Customer Name*"
                            register={register}
                            error={errors.customer?.name?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="customer.alternateContact"
                            label="Alternate Contact"
                            register={register}
                            error={errors.customer?.alternateContact?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="customer.email"
                            label="Email"
                            register={register}
                            error={errors.customer?.email?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="customer.city"
                            label="City"
                            register={register}
                            error={errors.customer?.city?.message}
                        />
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={3} className="page-section" id="trip-details">
                    <Grid container item xs={6} justify="space-between">
                        <Grid item className={classes.halfFields}>
                            <DatePicker
                                name="trip.start.date"
                                label="Date"
                                fullWidth={false}
                                control={control}
                                error={errors.trip?.start?.date?.message}
                            />
                        </Grid>
                        <Grid item className={classes.halfFields}>
                            <TimePicker
                                name="trip.start.time"
                                label="Time"
                                fullWidth={false}
                                control={control}
                                error={errors.trip?.start?.time?.message}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={6} justify="space-between">
                        <Grid item className={classes.halfFields}>
                            <DatePicker
                                name="trip.end.date"
                                label="Date"
                                fullWidth={false}
                                control={control}
                                error={errors.trip?.end?.date?.message}
                            />
                        </Grid>
                        <Grid item className={classes.halfFields}>
                            <TimePicker
                                name="trip.end.time"
                                label="Time"
                                fullWidth={false}
                                control={control}
                                error={errors.trip?.end?.time?.message}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="trip.pickUp"
                            label="Pick-up*"
                            register={register}
                            error={errors.trip?.pickUp?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="trip.dropOff"
                            label="Drop-off*"
                            register={register}
                            error={errors.trip?.dropOff?.message}
                        />
                    </Grid>
                    <Grid container item xs={6} justify="space-between">
                        <Grid item className={classes.halfFields}>
                            <Autocomplete
                                name="trip.bookingType"
                                label="Booking Type*"
                                fullWidth={false}
                                control={control}
                                error={errors.trip?.bookingType?.message}
                            />
                        </Grid>
                        <Grid item className={classes.halfFields}>
                            <NumberField
                                name="trip.numberPax"
                                label="Number of Pax*"
                                fullWidth={false}
                                register={register}
                                error={errors.trip?.numberPax?.message}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            name="trip.vehicleType"
                            label="Vehicle Type*"
                            control={control}
                            error={errors.trip?.vehicleType?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="trip.itinerary"
                            label="Itinerary"
                            register={register}
                            error={errors.trip?.itinerary?.message}
                        />
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid
                    container
                    spacing={3}
                    justify="space-between"
                    className="page-section"
                    id="estimates-charges">
                    <Grid item className={classes.fifthFields}>
                        <TextField
                            name="estimate.fare"
                            label="Estimated Fare"
                            register={register}
                            error={errors.estimate?.fare?.message}
                        />
                    </Grid>
                    <Grid item className={classes.fifthFields}>
                        <TextField
                            name="estimate.totalDistance"
                            label="Total Kms"
                            register={register}
                            error={errors.estimate?.totalDistance?.message}
                        />
                    </Grid>
                    <Grid item className={classes.fifthFields}>
                        <TextField
                            name="estimate.extraFarePerKm"
                            label="Extra"
                            register={register}
                            error={errors.estimate?.extraFarePerKm?.message}
                        />
                    </Grid>
                    <Grid item className={classes.fifthFields}>
                        <TextField
                            name="estimate.totalTime"
                            label="Total Time"
                            register={register}
                            error={errors.estimate?.totalTime?.message}
                        />
                    </Grid>
                    <Grid item className={classes.fifthFields}>
                        <TextField
                            name="estimate.extraFarePerHr"
                            label="Extra"
                            register={register}
                            error={errors.estimate?.extraFarePerHr?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            name="otherCharge"
                            label="Other Charges"
                            control={control}
                            error={errors.otherCharge?.message}
                        />
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid
                    container
                    spacing={3}
                    justify="space-between"
                    className="page-section"
                    id="payment-details">
                    {fields.map((item, index) => (
                        <Grid
                            container
                            item
                            xs={12}
                            key={index}
                            className={classes.paymentFieldArrayContainer}>
                            <Grid container item xs={6} justify="space-between">
                                <Grid item className={classes.halfFields}>
                                    <DatePicker
                                        name={`additional.payment[${index}].date`}
                                        label="Date"
                                        defaultValue={item.date}
                                        fullWidth={false}
                                        control={control}
                                        error={errors.additional?.payment![index]?.date?.message}
                                    />
                                </Grid>
                                <Grid item className={classes.halfFields}>
                                    <TextField
                                        name={`additional.payment[${index}].amount`}
                                        label="Amount (Rs)"
                                        defaultValue={item.amount}
                                        register={register}
                                        error={errors.additional?.payment![index]?.amount?.message}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    name={`additional.payment[${index}].method`}
                                    label="Method"
                                    defaultValue={item.method}
                                    control={control}
                                    error={errors.additional?.payment![index]?.method?.message}
                                />
                            </Grid>
                            <IconButton
                                onClick={() => remove(index)}
                                className={classes.closeIconButton}>
                                <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                        </Grid>
                    ))}
                    <Button
                        onClick={() =>
                            append({
                                date: '',
                                amount: 0,
                                method: null,
                                credit: false,
                            })
                        }
                        className={classes.addPaymentButton}
                        disableRipple>
                        <AddIcon className={classes.addIcon} />
                        &nbsp;ADD PAYMENT
                    </Button>
                    <Grid item xs={12} className="page-section" id="additional-notes">
                        <Autocomplete
                            name="additional.notes"
                            label="Additional Notes"
                            control={control}
                            error={errors.additional?.notes?.message}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" className={classes.actionButtons}>
                    <Button variant="outlined" color="primary" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default BookingForm;
