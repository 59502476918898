import Bookings from './Bookings';
import Car from './Car';
import Controls from './Controls';
import Document from './Document';
import DownArrow from './DownArrow';
import Drivers from './Drivers';
import FleetlyLogo from './FleetlyLogo';
import More from './More';
import NoBookings from './NoBookings';
import Profile from './Profile';
import UserRatings from './UserRatings';
import Vehicles from './Vehicles';

export type SVGProps = {
    name: string;
    fill?: string;
    width?: number | string;
    height?: number | string;
    style?: React.CSSProperties;
    className?: string;
    viewBox?: string;
};

const SVG = (props: SVGProps) => {
    const { name } = props;
    switch (name) {
        case 'profile':
            return <Profile {...props} />;
        case 'controls':
            return <Controls {...props} />;
        case 'bookings':
            return <Bookings {...props} />;
        case 'user-ratings':
            return <UserRatings {...props} />;
        case 'vehicles':
            return <Vehicles {...props} />;
        case 'drivers':
            return <Drivers {...props} />;
        case 'fleetly-logo':
            return <FleetlyLogo {...props} />;
        case 'down-arrow':
            return <DownArrow {...props} />;
        case 'more':
            return <More {...props} />;
        case 'car':
            return <Car {...props} />;
        case 'document':
            return <Document {...props} />;
        case 'no-bookings':
            return <NoBookings {...props} />;
        default:
            return <div />;
    }
};

export default SVG;
