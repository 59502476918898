import {
    FETCH_ALL_BOOKINGS_FAIL,
    FETCH_ALL_BOOKINGS_INIT,
    FETCH_ALL_BOOKINGS_SUCCESS,
} from './types';
import { Booking, BookingActionTypes } from './types';

export const initialState: Booking = {
    loading: false,
    offset: 0,
    limit: 8,
    totalCount: 0,
    totalPages: 0,
    bookings: [],
};

const reducer = (state = initialState, action: BookingActionTypes) => {
    switch (action.type) {
        case FETCH_ALL_BOOKINGS_INIT:
            return Object.assign({}, state, {
                loading: true,
            });
        case FETCH_ALL_BOOKINGS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                bookings: action.payload,
            });
        case FETCH_ALL_BOOKINGS_FAIL:
            return Object.assign({}, state, {
                loading: false,
                // totalCount: 1,
                // totalPages: 1,
                // bookings: [
                //     {
                //         id: 'ksjfkjsf',
                //         resId: 'BT0517-RC1004',
                //         customerName: 'Vinayak Mishra',
                //         customerMobile: '+91-9876512345',
                //         pickupDate: '02-05-2018, 12:55 PM',
                //         pickupLocation: 'Cochin International Airp…',
                //         dropOffDate: '05-05-2018, 3:30 PM',
                //         dropOffLocation: 'Cochin International Airp…',
                //         balanceAmount: 2500,
                //         vehicle: 'AC Innova or Similar',
                //         noOfDays: '5 Day(s), Outstation',
                //         driverName: 'Ajith John',
                //         driverContact: '+91-9876511115',
                //         vehicleRegNumber: '+91-9876511115',
                //     },
                // ],
            });
        default:
            return state;
    }
};

export default reducer;
