import { InputAdornment, TextField as TextFieldComponent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';

import styles from './styles';

type TextFieldProps = Partial<Pick<UseFormMethods, 'register' | 'errors'>> & {
    name: string;
    label?: string;
    suffix?: string;
    error?: string;
    defaultValue?: string;
};

const TextField = ({ name, label, suffix, register, error, defaultValue }: TextFieldProps) => {
    const classes = styles();

    return (
        <TextFieldComponent
            inputRef={register}
            name={name}
            label={label}
            defaultValue={defaultValue}
            fullWidth
            error={!!error}
            helperText={error}
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
            InputProps={{
                endAdornment: suffix && (
                    <InputAdornment position="end">
                        <Typography className={classes.endAdornmentText}>{suffix}</Typography>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default TextField;
