import { array, boolean, number, object, string } from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const emailRegExp = /^(?=.{1,64}@)(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const schema = object().shape({
    agency: object().shape({
        name: string().required('This field is mandatory*'),
        contactNumber: string()
            .required('This field is mandatory*')
            .matches(phoneRegExp, 'Contact number is not valid'),
        bookedBy: string(),
        bookingReference: string(),
    }),
    company: object().shape({
        name: string().required('This field is mandatory*'),
        contactNumber: string()
            .required('This field is mandatory*')
            .matches(phoneRegExp, 'Contact number is not valid'),
        bookedBy: string(),
        bookingReference: string(),
    }),
    customer: object().shape({
        contactNumber: string()
            .required('This field is mandatory*')
            .matches(phoneRegExp, 'Contact number is not valid'),
        name: string().required('This field is mandatory*'),
        alternateContact: string()
            // .required('This field is mandatory*')
            .matches(phoneRegExp, 'Alternate contact number is not valid'),
        email: string()
            // .required('This field is mandatory*')
            .matches(emailRegExp, 'Email is not valid'),
        // city: string().required('This field is mandatory*'),
    }),
    trip: object().shape({
        start: object().shape({
            date: string().required('This field is mandatory*'),
            time: string().required('This field is mandatory*'),
        }),
        end: object().shape({
            date: string().required('This field is mandatory*'),
            time: string().required('This field is mandatory*'),
        }),
        pickUp: string().required('This field is mandatory*'),
        dropOff: string().required('This field is mandatory*'),
        bookingType: object()
            .shape({
                value: string(),
                label: string(),
            })
            .nullable()
            .required('This field is mandatory*'),
        numberPax: number()
            .integer()
            .min(1, 'Minimum 1 person')
            .required('This field is mandatory*'),
        vehicleType: object()
            .shape({
                value: string(),
                label: string(),
            })
            .nullable()
            .required('This field is mandatory*'),
        // itinerary: string().required('This field is mandatory*'),
    }),
    estimate: object().shape({
        fare: number().integer().min(1, "Can't be < 1").required('This field is mandatory*'),
        totalDistance: number()
            .integer()
            .min(1, "Can't be < 1")
            .required('This field is mandatory*'),
        extraFarePerKm: number().integer().min(1, "Can't be < 1"),
        totalTime: number().integer().min(1, "Can't be < 1").required('This field is mandatory*'),
        extraFarePerHr: number()
            .integer()
            .min(1, "Can't be < 1")
            .required('This field is mandatory*'),
    }),
    otherCharge: object()
        .shape({
            value: string(),
            label: string(),
        })
        .nullable()
        .required('This field is mandatory*'),
    additional: object().shape({
        payment: array().of(
            object()
                .shape({
                    date: string().required('This field is mandatory*'),
                    amount: number()
                        .nullable()
                        .integer()
                        .min(1, "Can't be < 1")
                        .required('This field is mandatory*'),
                    method: object()
                        .shape({
                            value: string(),
                            label: string(),
                        })
                        .nullable()
                        .required('This field is mandatory*'),
                    credit: boolean().nullable(),
                })
                .required('This field is mandatory*'),
        ),
        notes: string(),
    }),
});

export default schema;
