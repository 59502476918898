import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
    },
    sectionContainer: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(8),
        },
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        width: '100%',
        fontSize: 14,
        fontWeight: 900,
        textTransform: 'uppercase',
    },
    pdfThumbnail: {
        width: 125,
        height: 180,
        backgroundColor: theme.palette.common.white,
        boxShadow: '1px 2px 8px 0 rgba(0, 0, 0, 0.18)',
    },
    amountContainer: {
        maxWidth: theme.spacing(30),

        '&:not(:last-child)': {
            marginBottom: theme.spacing(3),
        },
    },
}));

export default styles;
