import SVG from '@Components/SVG';
import {
    Divider,
    Drawer as DrawerComponent,
    Grid,
    MenuItem,
    MenuList,
    useTheme,
} from '@material-ui/core';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import navItems, { navItem } from './navItems';
import styles from './styles';

const Drawer = () => {
    const classes = styles();
    const profileIcon = 'profile';
    const [menus, setMenus] = useState<navItem[]>([]);
    const [selectedNav, setSelectedNav] = useState('');
    const location = useLocation();
    const theme = useTheme();

    useEffect(() => {
        setMenus(navItems({ profileIcon }));
    }, []);

    const getSelectedNavItem = useCallback(
        (item: navItem) => {
            if (location.pathname.includes(item.url)) {
                setSelectedNav(item.url);
            }
        },
        [location.pathname],
    );

    useEffect(() => {
        menus.forEach(getSelectedNavItem);
    }, [getSelectedNavItem, menus]);

    const handleNavItemClick = (url: string) => {
        setSelectedNav(url);
    };

    return (
        <DrawerComponent
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left">
            <MenuItem component={Link} to="/home" button className={classes.mainLogo}>
                <Grid container className={classes.toolbar} justify="center" alignItems="center">
                    <SVG name="fleetly-logo" width={52} height={52} />
                </Grid>
            </MenuItem>
            <MenuList disablePadding>
                {menus.map(({ icon, url }, index) => (
                    <Fragment key={`${url}-${index}`}>
                        <MenuItem
                            component={Link}
                            to={url}
                            button
                            disableRipple
                            selected={selectedNav === url}
                            onClick={() => handleNavItemClick(url)}
                            classes={{
                                root: classes.menuItemRoot,
                                selected: classes.selectedMenuItem,
                            }}>
                            {
                                <SVG
                                    name={icon}
                                    width={32}
                                    height={32}
                                    fill={
                                        selectedNav === url ? theme.palette.common.white : undefined
                                    }
                                />
                            }
                        </MenuItem>
                        <Divider variant="middle" classes={{ middle: classes.middleDivider }} />
                    </Fragment>
                ))}
            </MenuList>
        </DrawerComponent>
    );
};

export default Drawer;
