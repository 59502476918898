import fetchAsync, { API_METHODS } from '@Store/api';
import { API_END_POINTS } from '@Store/api/constants';

import {
    CREATE_BOOKING_FAIL,
    CREATE_BOOKING_INIT,
    CREATE_BOOKING_SUCCESS,
    createBookingPayload,
    FETCH_ALL_BOOKINGS_FAIL,
    FETCH_ALL_BOOKINGS_INIT,
    FETCH_ALL_BOOKINGS_SUCCESS,
    fetchAllBookingsPayload,
} from './types';

export const fetchAllBookings = async ({ offset, limit, searchTerm }: fetchAllBookingsPayload) => {
    const { BOOKINGS, OFFSET, LIMIT, SEARCH_TERM } = API_END_POINTS;
    const searchQuery = searchTerm ? `${SEARCH_TERM}${searchTerm}` : '';
    const url = `${BOOKINGS}${OFFSET}${offset}${LIMIT}${limit}${searchQuery}`;
    const apiArgs = {
        method: API_METHODS.GET,
        url,
        TYPES: {
            requestType: FETCH_ALL_BOOKINGS_INIT,
            successType: FETCH_ALL_BOOKINGS_SUCCESS,
            failureType: FETCH_ALL_BOOKINGS_FAIL,
        },
    };
    const response = await fetchAsync(apiArgs);
    return response;
};

export const createBooking = async (data: createBookingPayload) => {
    const { BOOKINGS } = API_END_POINTS;
    const url = BOOKINGS;
    const apiArgs = {
        method: API_METHODS.POST,
        data,
        url,
        TYPES: {
            requestType: CREATE_BOOKING_INIT,
            successType: CREATE_BOOKING_SUCCESS,
            failureType: CREATE_BOOKING_FAIL,
        },
    };
    const response = await fetchAsync(apiArgs);
    return response;
};
