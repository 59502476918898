import { Grid, Typography } from '@material-ui/core';

import styles from './styles';

const Home = () => {
    const classes = styles();

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
            className={classes.page}>
            <header>
                <Typography variant="body2" className={classes.header}>
                    Fleetly
                </Typography>
            </header>
            <main>
                <Grid container item justify="center" direction="column" alignItems="center">
                    <Typography variant="h3" className={classes.comingSoon}>
                        Coming Soon
                    </Typography>
                </Grid>
            </main>
            <footer>
                <Typography variant="body2" className={classes.footer}>
                    @2021 <strong>Fleetly</strong>. Designed by&nbsp;<strong>Fleetly</strong>
                </Typography>
            </footer>
        </Grid>
    );
};

export default Home;
