import ComingSoon from '@Containers/ComingSoon';
import LoginPage from '@Pages/login';
import { Redirect, Switch } from 'react-router-dom';

import PrivateRoute from './helpers/PrivateRoute';
import PublicRoute from './helpers/PublicRoute';
import MainLayout from './MainLayout';

const AppRoutes = () => {
    return (
        <Switch>
            <Redirect from="/" to="/coming-soon" exact />
            <PublicRoute path="/login" exact component={LoginPage} title="Fleetly" />
            <PublicRoute path="/coming-soon" exact component={ComingSoon} title="Fleetly" />
            <PrivateRoute path="*" component={MainLayout} title="Fleetly" />
        </Switch>
    );
};

export default AppRoutes;
