import { KeyboardDatePicker } from '@material-ui/pickers';
import { Controller, UseFormMethods } from 'react-hook-form';

import styles from './styles';

type DatePickerProps = Partial<Pick<UseFormMethods, 'control' | 'errors'>> & {
    name: string;
    label?: string;
    error?: string;
    fullWidth?: boolean;
    defaultValue?: string;
};

const DatePicker = ({
    name,
    label,
    control,
    error,
    fullWidth = true,
    defaultValue,
}: DatePickerProps) => {
    const classes = styles();

    return (
        <Controller
            name={name}
            control={control}
            render={({ onChange, value }) => (
                <KeyboardDatePicker
                    margin="normal"
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    label={label}
                    emptyLabel="Date"
                    views={['year', 'month', 'date']}
                    format="dd-MM-yyyy"
                    autoOk
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    InputAdornmentProps={{
                        position: 'start',
                    }}
                    error={!!error}
                    helperText={error}
                    FormHelperTextProps={{
                        classes: { root: classes.helperText },
                    }}
                    className={fullWidth ? undefined : classes.fieldMaxWidth}
                />
            )}
        />
    );
};

export default DatePicker;
