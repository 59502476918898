import { LOGIN_FAIL, LOGIN_INIT, LOGIN_SUCCESS } from './types';
import { Login, LoginActionTypes } from './types';

export const initialState: Login = {
    loading: false,
};

const reducer = (state = initialState, action: LoginActionTypes) => {
    switch (action.type) {
        case LOGIN_INIT:
            return Object.assign({}, state, { loading: true });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, { loading: false });
        case LOGIN_FAIL:
            return Object.assign({}, state, { loading: false });
        default:
            return state;
    }
};

export default reducer;
