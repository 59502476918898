import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    helperText: theme.mixins.helperText,
    adornmentButtons: {
        '&:hover': {
            // backgroundColor: 'transparent',
        },
    },
    adornmentIcons: {
        fontSize: theme.spacing(2),
        padding: 0,
    },
    fieldMaxWidth: theme.mixins.fieldMaxWidth,
    alignCenter: {
        textAlign: 'center',
    },
    noRightPadding: {
        'label + &': {
            paddingRight: 0,
        },
    },
}));

export default styles;
