import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { KeyboardEvent, MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles';

const MoreOptionsPopper = ({
    open,
    anchorEl,
    closePopper,
    bookingId,
    handleDownload,
    handleResend,
    cancelBooking,
}: any) => {
    const classes = styles();
    const handleClose = (event: MouseEvent<EventTarget>) => {
        if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
            return;
        }

        closePopper();
    };

    function handleListKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            closePopper();
        }
    }

    const handleDownloadVoucher = () => {
        handleDownload(bookingId);
    };

    const handleResendSms = () => {
        handleResend(bookingId);
    };

    const handleCancel = (event: MouseEvent<EventTarget>) => {
        event.stopPropagation();
        cancelBooking(bookingId);
    };

    return (
        <Popper open={open} anchorEl={anchorEl} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                id="more-list-grow"
                                onKeyDown={handleListKeyDown}>
                                <MenuItem
                                    component={Link}
                                    to={{
                                        pathname: `/bookings/${bookingId}/edit`,
                                    }}
                                    classes={{
                                        root: classes.newBookingMenuListItem,
                                    }}
                                    onClick={handleClose}>
                                    Edit
                                </MenuItem>
                                <MenuItem
                                    onClick={handleDownloadVoucher}
                                    classes={{
                                        root: classes.newBookingMenuListItem,
                                    }}>
                                    Download Voucher
                                </MenuItem>
                                <MenuItem
                                    onClick={handleResendSms}
                                    classes={{
                                        root: classes.newBookingMenuListItem,
                                    }}>
                                    Resend SMS
                                </MenuItem>
                                <MenuItem
                                    onClick={handleCancel}
                                    classes={{
                                        root: classes.newBookingMenuListItem,
                                    }}>
                                    Cancel
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

export default MoreOptionsPopper;
