import { TextField as TextFieldComponent, TextFieldProps } from '@material-ui/core';
import { Autocomplete as AutocompleteComponent } from '@material-ui/lab';
import { optionType } from '@Store/common/types';
import { ChangeEvent } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';

import styles from './styles';

type AutocompleteProps = Partial<Pick<UseFormMethods, 'control' | 'errors'>> & {
    name: string;
    label?: string;
    error?: string;
    fullWidth?: boolean;
    defaultValue?: optionType;
};

const options = [
    { label: 'Item 1', value: '1' },
    { label: 'Item 2', value: '2' },
];

const Autocomplete = ({
    name,
    label,
    control,
    error,
    fullWidth = true,
    defaultValue,
}: AutocompleteProps) => {
    const classes = styles();

    return (
        <Controller
            name={name}
            control={control}
            render={({ onChange }) => (
                <AutocompleteComponent
                    onChange={(_event: ChangeEvent<{}>, value: { label: string; value: string }) =>
                        onChange(value)
                    }
                    options={options}
                    defaultValue={defaultValue}
                    // fullWidth
                    autoComplete
                    disableClearable
                    className={`${classes.autocomplete} ${
                        fullWidth ? undefined : classes.fieldMaxWidth
                    }`}
                    filterOptions={(option) => option}
                    getOptionLabel={(option: optionType) => option?.value}
                    getOptionSelected={(option, selected) => option?.value === selected?.value}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                        <TextFieldComponent
                            {...params}
                            label={label}
                            variant="standard"
                            error={!!error}
                            helperText={error}
                            FormHelperTextProps={{
                                classes: { root: classes.helperText },
                            }}
                        />
                    )}
                />
            )}
        />
    );
};

export default Autocomplete;
