import AppBar from '@Containers/AppBar';
import Drawer from '@Containers/Drawer';
import { Grid } from '@material-ui/core/';
import Bookings from '@Pages/bookings';
import EditBooking from '@Pages/bookings/[bookingId]/edit';
import AddBooking from '@Pages/bookings/add';
import Home from '@Pages/home';
import { Switch } from 'react-router-dom';

import PrivateRoute from './helpers/PrivateRoute';
import styles from './styles';

const MainLayout = () => {
    const classes = styles();

    return (
        <Grid className={classes.root}>
            <AppBar />
            <Drawer />
            <main className={classes.content}>
                <Grid className={classes.toolbar} />
                <Grid className={classes.mainContent}>
                    <Switch>
                        <PrivateRoute
                            path="/bookings/:id/edit"
                            component={EditBooking}
                            title="Edit Booking"
                        />
                        <PrivateRoute
                            path="/bookings/add"
                            component={AddBooking}
                            title="Add Booking"
                        />
                        <PrivateRoute path="/bookings" component={Bookings} title="Bookings" />
                        <PrivateRoute path="/" component={Home} title="Fleetly" />
                    </Switch>
                </Grid>
            </main>
        </Grid>
    );
};

export default MainLayout;
