import { SVGProps } from '.';

const Vehicles = ({
    style = {},
    fill = '#000000',
    width = '512',
    height = '512',
    viewBox = '0 0 512 512',
    className = '',
}: SVGProps) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        className={className}
        xmlns="http://www.w3.org/2000/svg">
        <g id="_13-car" data-name="13-car">
            <g id="linear_color" data-name="linear color">
                <circle cx="120" cy="288" r="12" />
                <circle cx="408" cy="288" r="12" />
                <path
                    fill={fill}
                    d="M477.4,193.036l-88.329-13.4L304.479,110.02A44.128,44.128,0,0,0,276.53,100H159.38a43.778,43.778,0,0,0-34.36,16.514L74.232,180H40A36.04,36.04,0,0,0,4,216v40a44.049,44.049,0,0,0,44,44H69.413a51.988,51.988,0,0,0,101.174,0H357.413a51.988,51.988,0,0,0,101.174,0H472a36.04,36.04,0,0,0,36-36V228.63A35.794,35.794,0,0,0,477.4,193.036Zm-188.165-64.48L351.748,180H236V124h40.53A20.068,20.068,0,0,1,289.235,128.556ZM143.762,131.5A19.9,19.9,0,0,1,159.38,124H164v56H104.967ZM120,316a28,28,0,1,1,28-28A28.032,28.032,0,0,1,120,316Zm288,0a28,28,0,1,1,28-28A28.032,28.032,0,0,1,408,316Zm76-52a12.013,12.013,0,0,1-12,12H458.587a51.988,51.988,0,0,0-101.174,0H170.587a51.988,51.988,0,0,0-101.174,0H48a20.023,20.023,0,0,1-20-20V216a12.013,12.013,0,0,1,12-12H176a12,12,0,0,0,12-12V124h24v68a12,12,0,0,0,12,12H389.684L473.8,216.764A12.081,12.081,0,0,1,484,228.63Z"
                />
                <path fill={fill} d="M240,212H224a12,12,0,0,0,0,24h16a12,12,0,0,0,0-24Z" />
                <circle cx="120" cy="288" r="12" />
                <circle cx="408" cy="288" r="12" />
            </g>
        </g>
    </svg>
);

export default Vehicles;
