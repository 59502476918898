import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            // display: 'flex',
            // width: '100%',
            // justifyContent: 'center',
            flexWrap: 'wrap',
            padding: `${theme.spacing(3)}px 0`,
        },
        disabled: {
            cursor: 'default',
        },
    }),
);
