import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    textFieldStyle: {
        borderRadius: 5,
        border: 'solid 1px #ebedf4',
        backgroundColor: theme.palette.common.white,
    },
    searchIcon: {
        margin: '0 20px 0 10px',
        width: 20,
        color: '#748aa1',
    },
    closeIcon: {
        cursor: 'pointer',
        marginRight: 8,
        width: 20,
    },
    textField: {
        marginTop: 0,
    },
}));

export default styles;
