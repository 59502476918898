import TextField from '@Components/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@material-ui/core';
import { LoginProps } from '@Store/auth/types';
import storage from '@Utils/storage';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { object, string } from 'yup';

import styles from './styles';

type FormData = {
    email: string;
    password: string;
};

const schema = object().shape({
    email: string().required('This field is mandatory*'),
    password: string().required('This field is mandatory*'),
});

const Login = ({ login, location }: LoginProps) => {
    const classes = styles();
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            password: '',
        },
    });
    const { from } = location.state || { from: { pathname: '/' } };

    const onSubmit = useCallback(
        async ({ email, password }: FormData) => {
            const response = await login({
                email,
                password,
            });
            console.log(response);
            storage().set(
                'token',
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
            );
            history.replace(from ?? '/home');
        },
        [history, from, login],
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container alignItems="center" className={classes.login}>
                <Grid container direction="column" spacing={3} className={classes.formContainer}>
                    <Grid item>
                        <Typography variant="h1">Fleetly</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            register={register}
                            error={errors.email?.message}
                            name="email"
                            label="Email"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            register={register}
                            error={errors.password?.message}
                            name="password"
                            label="Password"
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default Login;
