import { ApiReturnType } from '@Store/api';
import { RouteComponentProps } from '@Store/common/types';

export const LOGIN_INIT = 'LOGIN:INIT';
export const LOGIN_SUCCESS = 'LOGIN:SUCCESS';
export const LOGIN_FAIL = 'LOGIN:FAIL';

export type Login = {
    loading: boolean;
};

export type loginPayload = {
    email: string;
    password: string;
};

export type LoginProps = RouteComponentProps & {
    login: (payload: loginPayload) => Promise<ApiReturnType>;
};

type LoginInitAction = {
    type: typeof LOGIN_INIT;
};

type LoginSuccessAction = {
    type: typeof LOGIN_SUCCESS;
};

type LoginFailAction = {
    type: typeof LOGIN_FAIL;
};

export type LoginActionTypes = LoginInitAction | LoginSuccessAction | LoginFailAction;
