import authReducer, { initialState as authState } from '@Store/auth/reducer';
import bookingReducer, { initialState as bookingState } from '@Store/booking/reducer';
import commonReducer, { initialState as commonState } from '@Store/common/reducer';
import { combineReducers } from 'redux';

export const initialState = {
    auth: authState,
    booking: bookingState,
    common: commonState,
};

export default combineReducers({
    auth: authReducer,
    booking: bookingReducer,
    common: commonReducer,
});
