import { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface IPageProps extends RouteProps {
    title: string;
    updatePageTitle: (pageTitle: string) => void;
}

const Page = ({ title, updatePageTitle, ...rest }: IPageProps) => {
    useEffect(() => {
        document.title = 'Fleetly | ' + title;
        updatePageTitle(title);
    }, [title, updatePageTitle]);

    return <Route {...rest} />;
};

export default Page;
