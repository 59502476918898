import { connect } from 'react-redux';

import { login } from '../../store/auth/actions';
import Login from './Login';

const mapDispatchToProps = () => ({
    login,
});

export default connect(mapDispatchToProps)(Login);
