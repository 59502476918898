import { SVGProps } from '.';

const More = ({
    style = {},
    fill = '#000000',
    width = 384,
    height = 384,
    viewBox = '0 0 384 384',
    className = '',
}: SVGProps) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        className={className}
        x="0px"
        y="0px"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 384 384"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <g>
            <g>
                <circle fill={fill} cx="192" cy="42.667" r="42.667" />
            </g>
        </g>
        <g>
            <g>
                <circle fill={fill} cx="192" cy="192" r="42.667" />
            </g>
        </g>
        <g>
            <g>
                <circle fill={fill} cx="192" cy="341.333" r="42.667" />
            </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
    </svg>
);

export default More;
