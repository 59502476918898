import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    login: {
        minHeight: '100vh',
    },
    formContainer: {
        textAlign: 'center',
    },
}));

export default useStyles;
