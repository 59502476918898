import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    menuList: {
        outline: 'none',
    },
    menuItem: {
        float: 'left',
        clear: 'both',
        padding: 0,

        '&:hover': {
            backgroundColor: 'transparent',
        },

        '&:not(:last-child)': {
            marginBottom: 30,
        },
    },
    menuItemLabel: {
        fontSize: 14,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        display: 'inline-block',
        paddingBottom: 1,
        borderBottom: `3px solid transparent`,
        cursor: 'pointer',
        padding: 0,

        '&.selectedMenu': {
            fontWeight: 900,
            color: theme.palette.primary.main,
            borderBottomColor: theme.palette.primary.main,
        },
    },
    navContainer: {
        height: '100%',
        position: 'sticky',
        top: 122,
        zIndex: 1,
    },
}));

export default styles;
