import { ApiReturnType } from '@Store/api';
import { optionType } from '@Store/common/types';

export const FETCH_ALL_BOOKINGS_INIT = 'FETCH:ALL_BOOKINGS:INIT';
export const FETCH_ALL_BOOKINGS_SUCCESS = 'FETCH:ALL_BOOKINGS:SUCCESS';
export const FETCH_ALL_BOOKINGS_FAIL = 'FETCH:ALL_BOOKINGS:FAIL';
export const CREATE_BOOKING_INIT = 'CREATE:BOOKING:INIT';
export const CREATE_BOOKING_SUCCESS = 'CREATE:BOOKING:SUCCESS';
export const CREATE_BOOKING_FAIL = 'CREATE:BOOKING:FAIL';

export type BookingType = {
    id: string;
    resId: string;
    customerName: string;
    customerMobile: string;
    pickupDate: string;
    pickupLocation: string;
    dropOffDate: string;
    dropOffLocation: string;
    balanceAmount: number;
    vehicle: string;
    noOfDays: string;
    driverName: string;
    driverContact: string;
    vehicleRegNumber: string;
};

export type Booking = {
    loading: boolean;
    offset: number;
    limit: number;
    totalCount: number;
    totalPages: number;
    bookings: BookingType[];
};

export type BookingsListStateProps = {
    loading: boolean;
    totalCount: number;
    totalPages: number;
    bookings: BookingType[];
};

export type fetchAllBookingsPayload = {
    offset: number;
    limit: number;
    searchTerm?: string;
};

export type BookingsListDispatchProps = {
    fetchAllBookings: (data: fetchAllBookingsPayload) => Promise<ApiReturnType>;
};

export type BookingsListProps = BookingsListStateProps & BookingsListDispatchProps;

export type createBookingPayload = {};

type BookingPayment = {
    date: string;
    amount: number;
    method: optionType | null;
    credit: boolean;
};

export type AddEditBookingFormData = {
    agency: {
        name: string;
        contactNumber: string;
        bookedBy: string;
        bookingReference: string;
    };
    company: {
        name: string;
        contactNumber: string;
        bookedBy: string;
        bookingReference: string;
    };
    customer: {
        contactNumber: string;
        name: string;
        alternateContact: string;
        email: string;
        city: string;
    };
    trip: {
        start: {
            date: string;
            time: string;
        };
        end: {
            date: string;
            time: string;
        };
        pickUp: string;
        dropOff: string;
        bookingType: optionType | null;
        numberPax: number;
        vehicleType: optionType | null;
        itinerary: string;
    };
    estimate: {
        fare: number;
        totalDistance: number;
        extraFarePerKm: number;
        totalTime: number;
        extraFarePerHr: number;
    };
    otherCharge: optionType | null;
    additional: {
        payment: BookingPayment[];
        notes: string;
    };
};

export enum BOOKING_TYPE {
    RETAIL = 'retail',
    AGENT = 'agent',
    CORPORATE = 'corporate',
}

export type bookingFormStateType = {
    type: BOOKING_TYPE;
};

type FetchAllBookingsInitAction = {
    type: typeof FETCH_ALL_BOOKINGS_INIT;
};

type FetchAllBookingsSuccessAction = {
    type: typeof FETCH_ALL_BOOKINGS_SUCCESS;
    payload: BookingType[];
};

type FetchAllBookingsFailAction = {
    type: typeof FETCH_ALL_BOOKINGS_FAIL;
};

type CreateBookingInitAction = {
    type: typeof CREATE_BOOKING_INIT;
};

type CreateBookingSuccessAction = {
    type: typeof CREATE_BOOKING_SUCCESS;
};

type CreateBookingFailAction = {
    type: typeof CREATE_BOOKING_FAIL;
};

export type BookingActionTypes =
    | FetchAllBookingsInitAction
    | FetchAllBookingsSuccessAction
    | FetchAllBookingsFailAction
    | CreateBookingInitAction
    | CreateBookingSuccessAction
    | CreateBookingFailAction;
