import { createMuiTheme } from '@material-ui/core';
import createMixins from '@material-ui/core/styles/createMixins';
import { Palette } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { AutocompleteClassKey, PaginationItemClassKey } from '@material-ui/lab';

// interface declaration merging to add custom mixins
declare module '@material-ui/core/styles/createMixins' {
    interface Mixins {
        mainLayout: CSSProperties;
        helperText: CSSProperties;
        fieldMaxWidth: CSSProperties;
        searchContainer: CSSProperties;
        boldTableCellText: CSSProperties;
        normalTableCellText: CSSProperties;
        countDetails: CSSProperties;
        emptyTableCell: CSSProperties;
    }
}

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        custom?: {
            menuDrawer: {
                boxShadowColor: CSSProperties['color'];
            };
            table: {
                headerColor: CSSProperties['color'];
            };
        };
    }
    interface ThemeOptions {
        custom?: {
            menuDrawer: {
                boxShadowColor: CSSProperties['color'];
            };
            table: {
                headerColor: CSSProperties['color'];
            };
        };
    }
}

interface Overrides extends CoreOverrides {
    // Define additional lab components here as needed....
    MuiAutocomplete?:
        | Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>>
        | undefined;
    MuiPaginationItem?:
        | Partial<Record<PaginationItemClassKey, CSSProperties | (() => CSSProperties)>>
        | undefined;
}

const defaultTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#184298',
            contrastText: '#748aa1',
        },
        secondary: {
            main: '#184298',
            contrastText: '#748aa1',
        },
        success: {
            main: '#15ff00',
        },
        error: {
            main: '#ff3167',
        },
        background: {
            default: '#f5f6fa',
        },
        divider: 'rgba(24, 65, 149, 0.12)',
        text: {
            primary: '#748aa1',
            secondary: '#31394d',
        },
    },
    custom: {
        menuDrawer: {
            boxShadowColor: '#e5e9f2',
        },
        table: {
            headerColor: '#ebedf4',
        },
    },
});

const overrides: Overrides = {
    ...defaultTheme.overrides,
    MuiButton: {
        containedPrimary: {
            color: defaultTheme.palette.common.white,
            backgroundImage: 'linear-gradient(to bottom, #174195, #1a5fe8)',
            height: 36,
            minWidth: 91,
            borderRadius: 5,
            fontSize: 14,
            fontWeight: 900,
            padding: '0 15px',
            textTransform: 'capitalize',
            boxShadow: ' 0 1px 7px 0 rgba(192, 199, 214, 0.38)',
        },
        outlinedPrimary: {
            borderColor: defaultTheme.palette.primary.main,
            borderRadius: 5,
            borderWidth: 2,
            height: 36,
            minWidth: 91,
            fontSize: 14,
            fontWeight: 900,
            color: defaultTheme.palette.primary.main,
            padding: '0 15px',
            textTransform: 'capitalize',
            boxShadow: ' 0 1px 7px 0 rgba(192, 199, 214, 0.38)',
        },
    },
    MuiFormControl: {
        root: {
            // minWidth: 252,
            width: '100%',
            marginTop: 15,
            backgroundColor: defaultTheme.palette.common.white,
            borderRadius: 5,
        },
    },
    MuiInput: {
        input: {
            fontSize: 14,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: defaultTheme.palette.text.primary,
        },
        formControl: {
            'label + &': {
                paddingLeft: 10,
                paddingRight: 10,
            },
        },
    },
    MuiInputLabel: {
        shrink: {
            transform: 'translate(0, 7px) scale(0.75)',
        },
        formControl: {
            left: 10,
        },
    },
    MuiFormLabel: {
        root: {
            fontSize: 14,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: defaultTheme.palette.text.primary,
        },
        filled: {},
    },
    MuiFormHelperText: {
        root: {
            fontSize: 10,
            fontWeight: 600,
            opacity: 0.7,
            left: 10,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#ff3167',
        },
    },
    MuiInputAdornment: {
        positionStart: {
            marginRight: -8,
        },
    },
    MuiIconButton: {
        root: {
            marginLeft: -12,
        },
        label: {
            width: 20,
            height: 20,
        },
    },
    MuiListItem: {
        root: {
            justifyContent: 'center',
        },
        gutters: {
            paddingTop: defaultTheme.spacing(3),
            paddingBottom: defaultTheme.spacing(3),
        },
    },
    MuiDivider: {
        root: {
            width: '100%',
        },
    },
    MuiAutocomplete: {
        endAdornment: {
            top: `calc(50% - 19px)`,
        },
    },
    MuiTableRow: {
        root: {
            backgroundColor: defaultTheme.palette.common.white,
            '&:last-child': {
                '& > .MuiTableCell-body:first-child': {
                    borderBottomLeftRadius: 5,
                },
                '& > .MuiTableCell-body:last-child': {
                    borderBottomRightRadius: 5,
                },
            },
        },
    },
    MuiTableCell: {
        root: {
            borderBottom: 'none',
        },
        head: {
            backgroundColor: defaultTheme.custom?.table.headerColor,
            color: defaultTheme.palette.common.black,
            padding: 20,
            '&:first-child': {
                borderTopLeftRadius: 5,
            },
            '&:last-child': {
                borderTopRightRadius: 5,
            },
        },
    },
    MuiPaginationItem: {
        textPrimary: {
            '&.Mui-selected': {
                backgroundImage: 'linear-gradient(to bottom, #174195, #1a5fe8)',
                color: defaultTheme.palette.common.white,
            },
        },
    },
};

const { breakpoints, spacing } = defaultTheme;

const typography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
    ...defaultTheme.typography,

    // Page title
    h1: {
        fontSize: 36,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: defaultTheme.palette.text.secondary,
    },

    h2: {
        ...defaultTheme.typography.h2,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    h3: {
        ...defaultTheme.typography.h3,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    h4: {
        ...defaultTheme.typography.h4,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    h5: {
        ...defaultTheme.typography.h5,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    h6: {
        ...defaultTheme.typography.h6,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    subtitle1: {
        ...defaultTheme.typography.subtitle1,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    subtitle2: {
        ...defaultTheme.typography.subtitle2,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    body1: {
        ...defaultTheme.typography.body1,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    body2: {
        ...defaultTheme.typography.body2,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    button: {
        ...defaultTheme.typography.button,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    caption: {
        ...defaultTheme.typography.caption,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },

    overline: {
        ...defaultTheme.typography.overline,
        fontFamily: ['Nunito', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
};

const mixins = createMixins(breakpoints, spacing, {
    ...defaultTheme.mixins,
    toolbar: {
        ...defaultTheme.mixins.toolbar,
        minHeight: 90,
        [''.concat(breakpoints.up('xs'), ' and (orientation: landscape)')]: {
            minHeight: 90,
        },
        [breakpoints.up('sm')]: {
            minHeight: 90,
        },
    },
    mainLayout: {
        paddingLeft: 42,
        paddingRight: 32,
    },
    helperText: {
        position: 'absolute',
        top: -20,
    },
    fieldMaxWidth: {
        minWidth: 'auto',
        width: '100%',
        maxWidth: 252,
    },
    searchContainer: {
        maxWidth: 250,
        width: '100%',
    },
    boldTableCellText: {
        fontSize: 14,
        fontWeight: 900,
    },
    normalTableCellText: {
        fontSize: 12,
        fontWeight: 400,
    },
    countDetails: {
        fontWeight: 900,
    },
    emptyTableCell: {
        padding: '100px 0',
    },
});

const theme = {
    ...defaultTheme,
    overrides,
    typography,
    mixins,
};

export default theme;
