import { SVGProps } from '.';

const FleetlyLogo = ({
    style = {},
    width = '292.000000pt',
    height = '320.000000pt',
    viewBox = '0 0 292.000000 320.000000',
    className = '',
}: SVGProps) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet">
        <g
            transform="translate(0.000000,320.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none">
            <path
                d="M485 3137 c-118 -20 -161 -31 -180 -48 -11 -9 -45 -32 -75 -52 -52
-32 -56 -38 -68 -92 -14 -59 -26 -86 -51 -118 -8 -9 -18 -36 -22 -60 -4 -23
-11 -54 -14 -69 -6 -21 -3 -27 13 -32 11 -4 35 -26 53 -49 18 -23 64 -69 101
-102 38 -33 82 -72 99 -87 16 -15 56 -46 89 -69 32 -22 61 -48 64 -56 4 -9
-19 -44 -60 -91 -36 -43 -73 -90 -81 -107 -8 -16 -26 -41 -38 -55 -13 -14 -33
-47 -45 -75 -12 -27 -35 -77 -50 -110 -71 -155 -94 -281 -93 -500 0 -156 16
-270 51 -380 29 -90 106 -246 164 -331 54 -80 231 -267 288 -304 19 -13 47
-34 61 -47 31 -28 285 -158 325 -166 16 -3 79 -20 139 -38 110 -32 111 -32
330 -32 219 0 220 0 330 32 61 18 123 35 139 38 49 10 268 127 356 192 86 63
185 155 251 233 38 46 99 133 99 142 0 3 15 29 33 58 37 59 109 246 126 323
28 134 31 165 31 282 0 122 -10 216 -32 298 -31 115 -98 278 -145 354 -29 46
-53 86 -53 90 0 4 -16 23 -36 42 -51 48 -66 82 -54 114 14 38 12 180 -5 248
-24 101 -82 205 -154 274 -65 64 -96 82 -112 66 -5 -5 -19 -36 -30 -69 -21
-60 -74 -160 -108 -203 -22 -28 -24 -28 -181 28 -164 58 -253 72 -449 72 -178
0 -333 -19 -404 -51 -21 -10 -46 -16 -56 -13 -9 2 -32 34 -51 71 -60 116 -102
186 -136 222 -18 19 -40 58 -49 85 -22 63 -31 75 -64 75 -14 1 -42 14 -61 30
-49 43 -98 39 -51 -4 21 -20 47 -74 38 -82 -8 -8 -48 15 -84 49 -34 31 -43 62
-21 69 28 10 -20 13 -67 5z m228 -219 c14 -18 42 -67 62 -108 21 -41 46 -87
56 -102 23 -31 24 -49 4 -42 -13 6 -132 135 -182 199 -47 60 -22 -3 32 -81 72
-105 115 -177 109 -184 -3 -2 -18 9 -32 26 -42 48 -142 138 -142 127 0 -5 27
-48 60 -94 33 -46 60 -87 60 -92 0 -18 -60 27 -129 97 -41 42 -77 74 -79 71
-4 -4 62 -97 114 -162 45 -55 22 -54 -46 2 -36 30 -78 64 -95 77 l-30 22 28
-34 c15 -19 42 -55 60 -79 18 -24 42 -54 55 -67 12 -13 22 -25 22 -28 0 -13
-58 18 -90 48 -43 41 -110 91 -110 82 0 -3 28 -39 63 -79 92 -105 112 -137 86
-137 -9 0 -53 37 -262 217 -35 30 -32 49 18 123 50 74 148 158 230 196 89 42
108 42 138 2z m1047 -342 c114 -26 136 -33 193 -60 34 -16 54 -31 51 -39 -7
-18 -131 -74 -193 -88 -35 -7 -91 -9 -155 -5 -86 6 -111 4 -167 -14 -75 -23
-121 -54 -117 -78 2 -13 -6 -18 -32 -19 -41 -3 -37 -7 -162 123 -71 73 -98
108 -98 126 0 24 5 26 103 46 56 12 116 23 132 26 63 9 381 -4 445 -18z m154
-260 c13 -29 29 -57 35 -60 15 -9 14 -59 -1 -74 -27 -27 -114 -3 -163 45 -40
39 -38 47 19 107 22 24 42 36 60 36 22 0 30 -8 50 -54z m-1250 -168 c44 -46
108 -116 143 -157 60 -70 63 -77 63 -125 0 -49 -2 -53 -35 -71 -53 -28 -91
-68 -116 -122 -20 -43 -23 -68 -27 -239 -4 -189 -4 -192 19 -213 19 -17 35
-21 88 -21 72 1 66 -3 215 117 35 28 67 49 70 46 18 -18 -104 -141 -179 -181
-69 -37 -102 -43 -158 -31 -52 12 -61 10 -52 -13 16 -41 137 -52 192 -18 18
11 67 47 109 80 41 33 80 60 85 60 19 0 -20 -56 -72 -104 -102 -92 -222 -129
-295 -92 -31 17 -44 13 -44 -12 0 -28 58 -56 115 -56 62 -1 98 17 195 94 41
33 78 60 82 60 45 0 -93 -136 -175 -174 -73 -33 -139 -34 -202 -4 -24 12 -48
19 -51 15 -12 -11 16 -54 44 -68 49 -27 172 -12 221 25 24 18 115 54 124 49
15 -9 -55 -63 -123 -95 -55 -26 -77 -31 -145 -32 -70 -1 -86 2 -126 26 -40 24
-48 25 -53 12 -12 -33 42 -86 106 -104 36 -11 100 2 168 32 61 28 130 49 130
39 0 -30 -99 -82 -205 -106 -89 -21 -151 -8 -207 43 l-40 36 -19 -24 c-10 -14
-18 -32 -16 -40 4 -26 84 -70 164 -90 97 -25 351 -28 453 -6 202 44 347 145
414 288 46 99 56 133 56 187 0 73 27 134 88 202 30 35 58 45 77 29 23 -19 18
-44 -17 -77 -52 -48 -98 -104 -98 -119 0 -22 69 -28 180 -14 182 22 256 19
525 -20 59 -8 113 -31 174 -72 24 -16 46 -27 49 -24 7 7 -38 117 -57 140 -11
13 -18 47 -21 116 -5 89 -8 103 -36 142 -34 49 -74 72 -154 88 -125 25 -237
52 -262 62 -34 13 -61 53 -53 78 17 53 168 171 300 233 61 29 154 112 180 161
11 20 26 36 35 36 14 0 125 -159 147 -210 5 -14 22 -50 36 -80 45 -96 69 -197
87 -359 17 -153 -17 -398 -74 -524 -13 -28 -34 -74 -46 -102 -53 -119 -192
-298 -299 -384 -31 -25 -58 -48 -61 -51 -8 -10 -98 -70 -105 -70 -4 0 -25 -13
-48 -29 -45 -31 -95 -54 -167 -77 -25 -8 -52 -18 -60 -23 -21 -11 -114 -37
-185 -52 -90 -18 -383 -15 -475 4 -85 19 -136 33 -165 46 -11 5 -54 21 -95 36
-71 26 -263 134 -285 159 -5 7 -34 31 -64 54 -29 24 -63 55 -75 70 -11 15 -34
38 -49 51 -15 13 -34 36 -43 52 -8 16 -27 43 -43 61 -34 40 -128 215 -148 278
-24 73 -31 105 -62 275 -13 73 -13 192 0 260 5 28 14 73 19 100 9 50 18 85 40
160 18 58 118 251 149 288 16 18 32 40 38 50 14 27 106 125 118 126 6 1 46
-36 89 -81z m826 63 c5 -11 10 -31 10 -44 0 -17 9 -29 30 -39 43 -21 90 -85
90 -122 0 -67 -55 -97 -108 -60 -21 14 -23 21 -16 70 6 54 6 54 -21 54 -41 0
-63 17 -75 58 -16 58 -13 82 13 92 37 15 66 11 77 -9z m242 -482 c55 -70 58
-77 58 -131 0 -31 -4 -59 -8 -62 -15 -9 -97 15 -118 35 -17 16 -22 36 -27 116
-7 96 1 129 27 120 6 -2 37 -37 68 -78z m-780 -41 c43 1 52 -9 44 -43 -9 -34
-48 -51 -137 -59 -63 -6 -78 -5 -89 9 -11 13 -9 20 16 47 35 37 82 59 109 51
11 -4 37 -6 57 -5z m1018 -88 c22 -11 40 -24 40 -28 0 -14 70 -34 99 -27 32 7
79 -9 97 -33 8 -9 14 -34 14 -54 l0 -38 -92 -2 c-51 0 -97 -1 -103 0 -44 5
-81 21 -97 42 -19 26 -35 117 -25 144 8 21 20 20 67 -4z"
            />
        </g>
    </svg>
);

export default FleetlyLogo;
