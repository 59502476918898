export type navItem = {
    name: string;
    url: string;
    label: string;
    icon: string;
};

type Props = {
    profileIcon: string;
};

const navItems = ({ profileIcon }: Props): navItem[] => {
    return [
        {
            name: 'Profile',
            url: '/profile',
            label: 'profile',
            icon: profileIcon,
        },
        {
            name: 'Controls',
            url: '/controls',
            label: 'controls',
            icon: 'controls',
        },
        {
            name: 'Bookings',
            url: '/bookings',
            label: 'bookings',
            icon: 'bookings',
        },
        {
            name: 'User Ratings',
            url: '/user-ratings',
            label: 'user-ratings',
            icon: 'user-ratings',
        },
        {
            name: 'Vehicles',
            url: '/vehicles',
            label: 'vehicles',
            icon: 'vehicles',
        },
        {
            name: 'Drivers',
            url: '/drivers',
            label: 'drivers',
            icon: 'drivers',
        },
    ];
};

export default navItems;
