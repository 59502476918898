import { isWidthDown, withWidth } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { PaginationItem } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import { useEffect, useState } from 'react';

import { useStyles } from './styles';

type PaginationProps = {
    limit: number;
    handlePagination: Function;
    page: number;
    totalPages: number;
    width: Breakpoint;
};

const PaginationComponent = (props: PaginationProps) => {
    const { limit, handlePagination, page, totalPages, width } = props;
    const classes = useStyles();
    const [screenWidth, setScreenWidth] = useState<Breakpoint | ''>('');

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        const offset = (value - 1) * limit;
        if (page !== value) {
            handlePagination(offset);
        }
    };

    useEffect(() => {
        if (isWidthDown('sm', width)) {
            setScreenWidth('sm');
        } else {
            setScreenWidth(width);
        }
    }, [width]);

    return (
        <div className={classes.root}>
            <Pagination
                count={totalPages}
                variant="text"
                color="primary"
                page={page}
                onChange={handleChange}
                siblingCount={screenWidth === 'sm' ? 0 : 1}
                boundaryCount={1}
                size={screenWidth === 'sm' ? 'small' : 'medium'}
                renderItem={(item) => (
                    <PaginationItem
                        {...item}
                        className={
                            item.type === 'page' && item.page === page
                                ? classes.disabled
                                : undefined
                        }
                    />
                )}
            />
        </div>
    );
};

export default withWidth()(PaginationComponent);
