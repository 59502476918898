import { SVGProps } from '.';

const UserRatings = ({
    style = {},
    fill = '#000000',
    width = '74',
    height = '74',
    viewBox = '0 0 74 74',
    className = '',
}: SVGProps) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        className={className}
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill={fill}
            d="M55.931,17.741a1,1,0,0,1-.918-.6,18.392,18.392,0,0,0-1.356-2.572,1,1,0,0,1,1.686-1.077,20.427,20.427,0,0,1,1.5,2.849,1,1,0,0,1-.916,1.4Z"
        />
        <path
            fill={fill}
            d="M37,46.47A21.619,21.619,0,0,1,19.416,37.4,21.4,21.4,0,0,1,15.4,24.88,21.625,21.625,0,0,1,37,3.28a20.466,20.466,0,0,1,3.008.221,1,1,0,1,1-.3,1.978A18.525,18.525,0,0,0,37,5.28a19.622,19.622,0,0,0-19.6,19.6,19.41,19.41,0,0,0,3.644,11.358,19.58,19.58,0,0,0,31.911,0A19.414,19.414,0,0,0,56.6,24.88a1.071,1.071,0,0,1-.01-.17,1,1,0,0,1,2-.094,1.9,1.9,0,0,1,.014.264A21.4,21.4,0,0,1,54.583,37.4,21.618,21.618,0,0,1,37,46.47Z"
        />
        <path
            fill={fill}
            d="M49.849,9.79a1,1,0,0,1-.633-.226,17.7,17.7,0,0,0-2.319-1.6A1,1,0,1,1,47.9,6.236a19.886,19.886,0,0,1,2.58,1.78,1,1,0,0,1-.634,1.774Z"
        />
        <path
            fill={fill}
            d="M53.77,37.823a1,1,0,0,1-.82-.425,19.517,19.517,0,0,0-31.9,0,1,1,0,0,1-1.637-1.15,21.519,21.519,0,0,1,35.174,0,1,1,0,0,1-.817,1.575Z"
        />
        <path
            fill={fill}
            d="M37,29.133a8.048,8.048,0,1,1,8.047-8.048A8.056,8.056,0,0,1,37,29.133Zm0-14.095a6.048,6.048,0,1,0,6.047,6.047A6.054,6.054,0,0,0,37,15.038Z"
        />
        <path
            fill={fill}
            d="M67.818,70.717a1.008,1.008,0,0,1-.465-.114l-4.681-2.462L57.989,70.6a1,1,0,0,1-1.45-1.055l.895-5.213-3.789-3.692a1,1,0,0,1,.554-1.706l5.235-.761,2.341-4.743a1,1,0,0,1,.9-.558h0a1,1,0,0,1,.9.558l2.341,4.743,5.235.761a1,1,0,0,1,.554,1.706L67.91,64.335l.894,5.213a1,1,0,0,1-.986,1.169ZM56.492,60.625l2.714,2.645a1,1,0,0,1,.287.885l-.64,3.734,3.354-1.763a1,1,0,0,1,.931,0l3.352,1.763-.639-3.734a1,1,0,0,1,.287-.885l2.714-2.645L65.1,60.08a1,1,0,0,1-.753-.547l-1.677-3.4-1.678,3.4a1,1,0,0,1-.753.547Z"
        />
        <path
            fill={fill}
            d="M42.147,70.717a1,1,0,0,1-.464-.114L37,68.141,32.317,70.6a1,1,0,0,1-1.45-1.055l.895-5.213-3.788-3.692a1,1,0,0,1,.554-1.706l5.235-.761L36.1,53.433a1.04,1.04,0,0,1,1.792,0l2.341,4.743,5.235.761a1,1,0,0,1,.554,1.706l-3.788,3.692.9,5.213a1,1,0,0,1-.986,1.169ZM37,66.011a.994.994,0,0,1,.465.115l3.354,1.763-.64-3.734a1,1,0,0,1,.287-.885l2.714-2.645-3.75-.545a1,1,0,0,1-.753-.547L37,56.135l-1.677,3.4a1,1,0,0,1-.753.547l-3.75.545,2.714,2.645a1,1,0,0,1,.287.885l-.64,3.734,3.354-1.763A.994.994,0,0,1,37,66.011Z"
        />
        <path
            fill={fill}
            d="M6.182,70.717A1,1,0,0,1,5.2,69.548l.894-5.213L2.3,60.643a1,1,0,0,1,.554-1.706l5.235-.761,2.341-4.743a1.041,1.041,0,0,1,1.793,0l2.341,4.743,5.235.761a1,1,0,0,1,.554,1.706l-3.789,3.692.895,5.213a1,1,0,0,1-1.45,1.055l-4.683-2.462L6.647,70.6A1.008,1.008,0,0,1,6.182,70.717Zm5.146-4.706a.994.994,0,0,1,.465.115l3.354,1.763-.64-3.734a1,1,0,0,1,.287-.885l2.714-2.645-3.749-.545a1,1,0,0,1-.753-.547l-1.678-3.4-1.677,3.4a1,1,0,0,1-.753.547l-3.75.545L7.862,63.27a1,1,0,0,1,.287.885L7.51,67.889l3.352-1.763A1,1,0,0,1,11.328,66.011Z"
        />
    </svg>
);

export default UserRatings;
