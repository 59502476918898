import { fetchAllBookings } from '@Store/booking/actions';
import { initialState } from '@Store/rootReducer';
import { connect } from 'react-redux';

import BookingsList from './BookingsList';

const mapStateToProps = ({ booking }: typeof initialState) => ({
    loading: booking.loading,
    totalCount: booking.totalCount,
    totalPages: booking.totalPages,
    bookings: booking.bookings,
});

const mapDispatchToProps = () => ({
    fetchAllBookings,
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingsList);
