import AppBarComponent from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

type AppBarProps = {
    pageTitle: string;
};

const AppBar = ({ pageTitle }: AppBarProps) => {
    const classes = styles();

    return (
        <AppBarComponent position="fixed" className={classes.appBar} color="inherit" elevation={0}>
            <Toolbar classes={{ root: classes.toolbarComponent }}>
                <Typography variant="h1" noWrap>
                    {pageTitle}
                </Typography>
            </Toolbar>
        </AppBarComponent>
    );
};

export default AppBar;
