import { createStyles, makeStyles } from '@material-ui/core/styles';

const drawerWidth = 81;

const useStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            backgroundColor: theme.palette.background.default,
        },
        toolbarComponent: theme.mixins.mainLayout,
    }),
);

export default useStyles;
