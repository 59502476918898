import { initialState } from '@Store/rootReducer';
import { connect } from 'react-redux';

import AppBar from './AppBar';

const mapStateToProps = ({ common }: typeof initialState) => ({
    pageTitle: common.pageTitle,
});

export default connect(mapStateToProps)(AppBar);
