import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    divider: {
        margin: `${theme.spacing(5)}px 0`,
    },
    halfFields: {
        width: '45%',
    },
    fifthFields: {
        width: '20%',
    },
    actionButtons: {
        gap: '30px',
        padding: 20,
        marginTop: 100,
    },
    paymentFieldArrayContainer: {
        position: 'relative',
    },
    closeIconButton: {
        position: 'absolute',
        right: -12,
        top: 10,
        bottom: 0,
        maxHeight: 20,
        maxWidth: 20,
        margin: 'auto',
    },
    closeIcon: {
        fontSize: 12,
    },
    addPaymentButton: {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        marginTop: -15,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    addIcon: {
        fontSize: 14,
    },
}));

export default styles;
