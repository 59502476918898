import { SVGProps } from '.';

const Drivers = ({
    style = {},
    fill = '#000000',
    width = '512',
    height = '512',
    viewBox = '0 0 64 64',
    className = '',
}: SVGProps) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        className={className}
        enableBackground="new 0 0 64 64"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill={fill}
            d="m49.4 34.5-8.8-3.8c.9-1.1 1.6-2.4 1.9-3.8l2.8-1.4c1-.5 1.7-1.5 1.7-2.7v-1.2c0-1.7-1.3-3-3-3-.2 0-.5 0-.7.1h-.3v-2c3-.9 5-3.6 5-6.7v-.1c0-2.2-1.4-4.1-3.4-4.7l-6.3-2.1c-4.1-1.4-8.5-1.4-12.6 0l-6.3 2.1c-2 .6-3.4 2.6-3.4 4.7 0 2.9 1.7 5.4 4.4 6.5l.6.2v2l-.3-.1c-1.6-.4-3.2.6-3.6 2.2-.1.4-.1.6-.1.9v1.2c0 1.1.6 2.2 1.7 2.7l2.8 1.4c.4 1.4 1.1 2.7 1.9 3.8l-8.8 3.8c-4 1.8-6.6 5.7-6.6 10.1v17.4h48v-17.5c0-4.3-2.6-8.3-6.6-10zm-7.6-1.2-2.2 5.1-5-3.8c.5-.1 1-.3 1.5-.6l.9-.4c.8-.4 1.6-.9 2.2-1.5zm2-12.7c.5-.1 1.1.2 1.2.7v.2 1.2c0 .4-.2.7-.6.9l-1.4.8c0-.2 0-.4 0-.6v-3zm-24.2 3.1c-.3-.2-.6-.5-.6-.9v-1.2c0-.6.4-1 1-1h.2l.8.2v3 .6zm2.3-8.8-.8-.3c-1.9-.8-3.2-2.6-3.1-4.7 0-1.3.8-2.4 2.1-2.8l6.2-2.1c3.7-1.3 7.7-1.3 11.4 0l6.2 2c1.3.4 2.1 1.6 2.1 2.9v.2c0 2.3-1.6 4.3-3.8 4.9l-.4-.2c-6-3.6-13.5-3.6-19.6 0zm18.3 1.2-2.8 2.2c-.5.4-1.2.7-1.9.7h-7.9c-.8 0-1.6-.3-2.1-.9l-1.9-1.9c5.2-2.9 11.5-2.9 16.6-.1zm-17.2 7.7v-5.4l1.1 1.1c.9.9 2.2 1.5 3.5 1.5h7.9c1.1 0 2.2-.4 3.1-1.1l2.3-1.8v5.7c0 3.4-1.9 6.5-5 8l-.9.4c-2 1-4.3 1-6.3 0l-.7-.3c-3.1-1.5-5-4.7-5-8.1zm9.7 15.1c-.4.4-1 .4-1.4 0l-1.8-1.8 2.5-1.9 2.5 1.9zm1.1 7.3c-1.2-.2-2.4-.2-3.6 0l.6-5.3c.8.3 1.6.3 2.4 0zm-9-14c.7.6 1.4 1.1 2.2 1.5l.9.4 1.5.6-5 3.8-2.2-5.1zm-4.8 27.8v-10h-2v10h-8v-15.5c0-3.6 2.1-6.8 5.4-8.2l5-2.2 3.2 7.5 4.3-3.2 1 1-.8 7.4c-5.1 2.1-7.5 7.9-5.4 13 0 .1.1.1.1.2zm11 0h-5.9c-1.3-2.2-1.5-5-.3-7.4l6.3 3.9v3.5zm-5.2-9.1c2.8-3.4 7.8-3.9 11.2-1.2.4.3.8.7 1.2 1.2l-6.2 3.9zm13.1 9.1h-5.9v-3.4l6.3-3.9c1.1 2.3.9 5.1-.4 7.3zm15.1 0h-8v-10h-2v10h-2.9c2.2-5 0-10.9-5.1-13.1-.1 0-.1-.1-.2-.1l-.8-7.4 1-1 4.3 3.2 3.2-7.5 5 2.2c3.3 1.4 5.4 4.7 5.4 8.2v15.5z"
        />
    </svg>
);

export default Drivers;
