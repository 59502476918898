import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
    newBookingMenuListItem: {
        justifyContent: 'flex-start',
        fontSize: 12,
    },
}));

export default styles;
