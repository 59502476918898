import { List, ListItem } from '@material-ui/core';
import { optionType } from '@Store/common/types';

import styles from './styles';

type FiltersProps = {
    options: optionType[];
    onClickItem: (item: optionType) => void;
};

const Filters = ({ options, onClickItem }: FiltersProps) => {
    const classes = styles();

    return (
        <List disablePadding className={classes.list}>
            {options.map((item) => (
                <ListItem
                    key={item.value}
                    onClick={() => onClickItem(item)}
                    className={classes.listItem}>
                    {item.label}
                </ListItem>
            ))}
        </List>
    );
};

export default Filters;
