import { Grid, Typography } from '@material-ui/core';

import styles from './styles';

const Voucher = () => {
    const classes = styles();

    return (
        <Grid container className={classes.container}>
            <Grid container className={classes.sectionContainer}>
                <Typography className={classes.sectionTitle}>Voucher</Typography>
                <Grid container className={classes.pdfThumbnail} />
            </Grid>
            <Grid container className={classes.sectionContainer}>
                <Typography className={classes.sectionTitle}>Summary</Typography>
                <Grid container className={classes.amountContainer} justify="space-between">
                    <Typography>Estimated Amount</Typography>
                    <Typography>₹ 0.00</Typography>
                </Grid>
                <Grid container className={classes.amountContainer} justify="space-between">
                    <Typography>Payment Received</Typography>
                    <Typography>₹ 0.00</Typography>
                </Grid>
                <Grid container className={classes.amountContainer} justify="space-between">
                    <Typography color="error">Payment Scheduled</Typography>
                    <Typography color="error">₹ 0.00</Typography>
                </Grid>
                <Grid container className={classes.amountContainer} justify="space-between">
                    <Typography>Balance</Typography>
                    <Typography>₹ 0.00</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Voucher;
