import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    list: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '30px',
    },
    listItem: {
        padding: 0,
        fontSize: 14,
        height: 22,
        paddingBottom: 1,
        borderBottom: `3px solid transparent`,
        cursor: 'pointer',
        marginBottom: -6,

        '&.selected': {
            fontWeight: 900,
            color: theme.palette.primary.main,
            borderBottomColor: theme.palette.primary.main,
            cursor: 'default',
        },
    },
}));

export default styles;
