import Filters from '@Components/Filters';
import Pagination from '@Components/Pagination';
import Search from '@Components/Search';
import SVG from '@Components/SVG';
import {
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { BookingsListProps } from '@Store/booking/types';
import { optionType, STATUS } from '@Store/common/types';
import findFirstAndLastIndices from '@Utils/findFirstAndLastIndices';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import MoreOptionsPopper from '../MoreOptionsPopper';
import NewBookingPopper from '../NewBookingPopper';
import styles from './styles';

export type queryType = {
    q?: string;
    page: number;
    limit?: number;
    status?: string;
    verticals?: string;
};

const BookingsList = ({
    totalCount,
    totalPages,
    bookings,
    fetchAllBookings,
}: BookingsListProps) => {
    const [openNewPopper, setOpenNewPopper] = useState(false);
    const [openMorePopper, setOpenMorePopper] = useState(false);
    const newBookingAnchorRef = useRef<HTMLButtonElement>(null);
    const morePopperAnchorRef = useRef<HTMLButtonElement>(null);
    const classes = styles();
    const history = useHistory();
    const location = useLocation();
    const [query, setQuery] = useState(new URLSearchParams(location.search));
    console.log(query.get('page'));
    const offset = Number(query.get('page')) > 0 ? Number(query.get('page')) - 1 : 0;
    // const [searchTerm] = useState(query.get('q') ? String(query.get('q')) : '');
    const [limit] = useState<number>(query.get('limit') ? Number(query.get('limit')) : 8);
    const [firstItem, lastItem] = findFirstAndLastIndices(offset, limit, totalCount);

    useEffect(() => {
        fetchAllBookings({ offset: offset * limit, limit });
    }, [fetchAllBookings, limit, offset]);

    const handleToggleNewPopper = () => {
        setOpenNewPopper((prevOpen) => !prevOpen);
    };

    const handleToggleMorePopper = () => {
        setOpenMorePopper((prevOpen) => !prevOpen);
    };

    const closeNewPopper = () => setOpenNewPopper(false);

    const closeMorePopper = () => setOpenMorePopper(false);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(openNewPopper);
    useEffect(() => {
        if (prevOpen.current === true && openNewPopper === false) {
            newBookingAnchorRef.current?.focus();
        }

        prevOpen.current = openNewPopper;
    }, [openNewPopper]);

    const handlePageChange = (_offset: number) => {
        const nextPage = _offset / limit + 1;
        const newQuery = new URLSearchParams(location.search);
        newQuery.set('page', String(nextPage));
        setQuery(newQuery);
        history.push(`/bookings?page=${nextPage}&limit=${limit}`);
    };

    const filterOptions = useMemo(
        () => [
            {
                label: 'Active',
                value: STATUS.ACTIVE,
            },
            {
                label: 'Upcoming',
                value: STATUS.UPCOMING,
            },
            {
                label: 'Ongoing',
                value: STATUS.ONGOING,
            },
            {
                label: 'Cancelled',
                value: STATUS.CANCELLED,
            },
        ],
        [],
    );

    const onClickFilterItem = (item: optionType) => {
        console.log(item);
    };

    return (
        <>
            <Grid container>
                <Grid container justify="space-between" alignItems="center">
                    <Grid className={classes.searchContainer}>
                        <Search />
                    </Grid>
                    <Grid className={classes.filtersNewButtonContainer}>
                        <Filters options={filterOptions} onClickItem={onClickFilterItem} />
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<SVG name="down-arrow" width={10} height={10} />}
                            ref={newBookingAnchorRef}
                            aria-controls={openNewPopper ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggleNewPopper}>
                            New
                        </Button>
                    </Grid>
                </Grid>
                <Grid container className={classes.table}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Res ID</TableCell>
                                    <TableCell>Guest</TableCell>
                                    <TableCell>Pick-up</TableCell>
                                    <TableCell>Drop-off</TableCell>
                                    <TableCell>Balance</TableCell>
                                    <TableCell>Vehicle</TableCell>
                                    <TableCell>Driver</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bookings?.length ? (
                                    bookings.map((booking) => (
                                        <TableRow key={booking.id}>
                                            <TableCell>
                                                <Grid container direction="column">
                                                    <Typography
                                                        className={classes.boldTableCellText}>
                                                        {booking.resId}
                                                    </Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container direction="column">
                                                    <Typography
                                                        className={classes.boldTableCellText}>
                                                        {booking.customerName}
                                                    </Typography>
                                                    <Typography
                                                        className={classes.normalTableCellText}>
                                                        {booking.customerMobile}
                                                    </Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container direction="column">
                                                    <Typography
                                                        className={classes.boldTableCellText}>
                                                        {booking.pickupDate}
                                                    </Typography>
                                                    <Typography
                                                        className={classes.normalTableCellText}>
                                                        {booking.pickupLocation}
                                                    </Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container direction="column">
                                                    <Typography
                                                        className={classes.boldTableCellText}>
                                                        {booking.dropOffDate}
                                                    </Typography>
                                                    <Typography
                                                        className={classes.normalTableCellText}>
                                                        {booking.dropOffLocation}
                                                    </Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container direction="column">
                                                    <Typography
                                                        className={classes.boldTableCellText}>
                                                        &#8377;&nbsp;{booking.balanceAmount}
                                                    </Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container direction="column">
                                                    <Typography
                                                        className={classes.boldTableCellText}>
                                                        {booking.vehicle}
                                                    </Typography>
                                                    <Typography
                                                        className={classes.normalTableCellText}>
                                                        {booking.noOfDays}
                                                    </Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container direction="column">
                                                    <Typography
                                                        className={classes.boldTableCellText}>
                                                        {booking.driverName}
                                                    </Typography>
                                                    <Typography
                                                        className={classes.normalTableCellText}>
                                                        {booking.driverContact}&#59;&nbsp;
                                                        {booking.vehicleRegNumber}
                                                    </Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton>
                                                    <SVG name="car" />
                                                </IconButton>
                                                <IconButton>
                                                    <SVG name="document" height={15} />
                                                </IconButton>
                                                <IconButton
                                                    ref={morePopperAnchorRef}
                                                    aria-controls={
                                                        openMorePopper
                                                            ? 'more-list-grow'
                                                            : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    onClick={handleToggleMorePopper}>
                                                    <SVG name="more" height={15} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} className={classes.emptyTableCell}>
                                            <SVG name="no-bookings" width="100%" />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {bookings?.length !== 0 && (
                    <Grid container justify="space-between" alignItems="center">
                        <Typography className={classes.countDetails}>
                            {`Showing ${firstItem} - ${lastItem} of ${totalCount} entries`}
                        </Typography>
                        <Pagination
                            limit={limit}
                            handlePagination={handlePageChange}
                            page={offset / limit + 1}
                            totalPages={totalPages}
                        />
                        <Grid className={classes.emptyDiv} />
                    </Grid>
                )}
            </Grid>
            <NewBookingPopper
                open={openNewPopper}
                anchorEl={newBookingAnchorRef.current}
                closePopper={closeNewPopper}
            />
            <MoreOptionsPopper
                open={openMorePopper}
                anchorEl={morePopperAnchorRef.current}
                closePopper={closeMorePopper}
            />
        </>
    );
};

export default BookingsList;
