import { IconButton, InputAdornment, TextField as TextFieldComponent } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { UseFormMethods } from 'react-hook-form';

import styles from './styles';

type NumberFieldProps = Partial<Pick<UseFormMethods, 'register' | 'errors'>> & {
    name: string;
    label?: string;
    error?: string;
    fullWidth?: boolean;
};
const NumberField = ({ name, label, register, error, fullWidth = true }: NumberFieldProps) => {
    const classes = styles();

    const handleClickShowPassword = () => {
        console.log('clicked');
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <TextFieldComponent
            inputRef={register}
            name={name}
            label={label}
            fullWidth
            error={!!error}
            helperText={error}
            className={fullWidth ? undefined : classes.fieldMaxWidth}
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
            InputProps={{
                classes: {
                    input: classes.alignCenter,
                    formControl: classes.noRightPadding,
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            className={classes.adornmentButtons}>
                            <RemoveIcon className={classes.adornmentIcons} />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            className={classes.adornmentButtons}>
                            <AddIcon className={classes.adornmentIcons} />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default NumberField;
