import store from '@Store/index';

export enum API_METHODS {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

type Payload = {
    baseUrl?: string;
    method?: `${API_METHODS}`;
    url: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
    headers?: { [key: string]: string };
    TYPES: {
        requestType: string;
        successType: string;
        failureType: string;
    };
    showErrorNotification?: boolean;
    authRequired?: boolean;
    formData?: boolean;
};

export type ApiReturnType = {
    success: boolean;
    status: number;
    statusText: string;
    response: never;
};

const fetchAsync = async ({
    baseUrl = process.env.REACT_APP_BASE_URL,
    method,
    url,
    data,
    headers,
    TYPES,
    showErrorNotification = false,
    authRequired = true,
    formData = false,
}: Payload): Promise<ApiReturnType> => {
    // creating api URL with appending parameters
    const API_URL = `${baseUrl}${url}`;
    const { dispatch } = store;

    const Headers = {
        ...headers,
        ...(authRequired && {}),
    };

    try {
        // Dispatching initial action
        if (TYPES.requestType) {
            dispatch({ type: TYPES.requestType });
        }

        const response = await fetch(API_URL, {
            method,
            body: formData ? data : JSON.stringify(data),
            headers: Headers,
        });
        const responseJson = await response.json();
        const modifiedJson = {
            success: !!responseJson.success,
            status: response.status,
            statusText: (responseJson.message as string) ?? response.statusText ?? '',
            response: responseJson.data as never,
        };

        // If request failed, dispatch failure action with modifiedJson as payload
        // and return modifiedJson
        if (!modifiedJson.success) {
            dispatch({ type: TYPES.failureType, errMessage: modifiedJson });
            if (showErrorNotification) {
                // add error notification here
            }
            return modifiedJson;
        }

        // If successful, dispatch success action with modifiedJson as payload
        // and return modifiedJson
        if (TYPES.successType) {
            dispatch({ type: TYPES.successType, payload: modifiedJson });
        }

        return modifiedJson;
    } catch (error) {
        // Dispatching failure action
        dispatch({ type: TYPES.failureType, errMessage: error });
        return {
            success: false,
            status: 400,
            statusText: 'Something went wrong',
            response: error as never,
        };
    }
};

export default fetchAsync;
