import { StaticContext } from 'react-router';
import { RouteComponentProps as RouteComponentPropsType } from 'react-router-dom';

export const UPDATE_PAGE_TITLE = 'UPDATE:PAGE_TITLE';

export type Common = {
    pageTitle: string;
};

export type optionType = {
    value: string;
    label: string;
};

export type RouteComponentProps = RouteComponentPropsType<
    {},
    StaticContext,
    {
        from: Location;
    }
>;

export enum STATUS {
    ACTIVE = 'ACTIVE',
    UPCOMING = 'UPCOMING',
    ONGOING = 'ONGOING',
    CANCELLED = 'CANCELLED',
}

type UpdatePageTitleAction = {
    type: typeof UPDATE_PAGE_TITLE;
    pageTitle: string;
};

export type CommonActionTypes = UpdatePageTitleAction;
