import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { ChangeEvent, useState } from 'react';

import styles from './styles';

const Search = ({ placeholder = 'Search', minCharacters = 3, handleChange = () => null }: any) => {
    const classes = styles();
    const [value, setValue] = useState('');

    const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if (event.target.value.length >= minCharacters) {
            handleChange(event.target.value);
        }
        if (event.target.value.length === 0) {
            handleChange('');
        }
    };

    const handleClearField = () => {
        setValue('');
        handleChange('');
    };

    return (
        <TextField
            id="search"
            value={value}
            onChange={handleSearchTermChange}
            placeholder={placeholder}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {value && value.length !== 0 && (
                            <CloseIcon onClick={handleClearField} className={classes.closeIcon} />
                        )}
                    </InputAdornment>
                ),
                disableUnderline: true,
            }}
            classes={{
                root: classes.textField,
            }}
        />
    );
};

export default Search;
