import { UPDATE_PAGE_TITLE } from './types';
import { Common, CommonActionTypes } from './types';

export const initialState: Common = {
    pageTitle: '',
};

const reducer = (state = initialState, action: CommonActionTypes) => {
    switch (action.type) {
        case UPDATE_PAGE_TITLE:
            return Object.assign({}, state, { pageTitle: action.pageTitle });
        default:
            return state;
    }
};

export default reducer;
