import Page from '@Containers/Page';
import storage from '@Utils/storage';
import { Redirect } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PublicRoute = ({ component: Component, ...rest }: any) => (
    <Page
        {...rest}
        render={(props) =>
            !storage().get('token') || props.location.pathname === '/coming-soon' ? (
                <Component {...props} />
            ) : (
                <Redirect to="/" />
            )
        }
    />
);

export default PublicRoute;
