import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 81;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            border: 'none',
            overflow: 'visible',
            boxShadow: `0 0 5px 0 ${theme.custom?.menuDrawer.boxShadowColor}`,
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        mainLogo: theme.mixins.toolbar,
        menuItemRoot: {
            justifyContent: 'center',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        selectedMenuItem: {
            overflow: 'visible',
            backgroundImage: `linear-gradient(to bottom, #174195, #1a5fe8)`,
            boxShadow: `0 1px 7px 0 rgb(192 199 214 / 38%)`,
            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '100%',
                height: '100%',
                width: 10,
                backgroundImage: `linear-gradient(to bottom, #174195, #1a5fe8)`,
                zIndex: 9999,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
            },
        },
        middleDivider: {
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: 20,
        },
    }),
);

export default useStyles;
