import fetchAsync, { API_METHODS } from '@Store/api';
import { API_END_POINTS } from '@Store/api/constants';

import { LOGIN_FAIL, LOGIN_INIT, LOGIN_SUCCESS, loginPayload } from './types';

export const login = async (data: loginPayload) => {
    const url = API_END_POINTS.LOGIN;
    const apiArgs = {
        method: API_METHODS.POST,
        data,
        url,
        authRequired: false,
        TYPES: {
            requestType: LOGIN_INIT,
            successType: LOGIN_SUCCESS,
            failureType: LOGIN_FAIL,
        },
    };
    const response = await fetchAsync(apiArgs);
    return response;
};
