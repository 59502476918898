import ScheduleIcon from '@material-ui/icons/Schedule';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { Controller, UseFormMethods } from 'react-hook-form';

import styles from './styles';

type TimePickerProps = Partial<Pick<UseFormMethods, 'control' | 'errors'>> & {
    name: string;
    label?: string;
    error?: string;
    fullWidth?: boolean;
};

const TimePicker = ({ name, label, control, error, fullWidth = true }: TimePickerProps) => {
    const classes = styles();

    return (
        <Controller
            name={name}
            control={control}
            render={({ onChange, value }) => (
                <KeyboardTimePicker
                    margin="normal"
                    value={value}
                    onChange={onChange}
                    label={label}
                    emptyLabel="Time"
                    autoOk
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    InputAdornmentProps={{
                        position: 'start',
                    }}
                    keyboardIcon={<ScheduleIcon />}
                    error={!!error}
                    helperText={error}
                    FormHelperTextProps={{
                        classes: { root: classes.helperText },
                    }}
                    className={fullWidth ? undefined : classes.fieldMaxWidth}
                />
            )}
        />
    );
};

export default TimePicker;
