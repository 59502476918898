import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    helperText: theme.mixins.helperText,
    autocomplete: {
        minWidth: 252,
    },
    fieldMaxWidth: theme.mixins.fieldMaxWidth,
}));

export default styles;
