import { SVGProps } from '.';

const Bookings = ({
    style = {},
    fill = '#000000',
    width = '64',
    height = '64',
    viewBox = '0 0 64 64',
    className = '',
}: SVGProps) => (
    <svg
        enableBackground="new 0 0 64 64"
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        className={className}
        xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                fill={fill}
                d="m59 1h-54c-2.206 0-4 1.794-4 4v42c0 2.206 1.794 4 4 4h12v5c0 .552.447 1 1 1h3v6h2v-6h18v6h2v-6h3c.553 0 1-.448 1-1v-5h4v12h2v-12h2v12h2v-12h2c2.206 0 4-1.794 4-4v-42c0-2.206-1.794-4-4-4zm-14 54h-26v-4h26zm16-8c0 1.103-.897 2-2 2h-54c-1.103 0-2-.897-2-2v-42c0-1.103.897-2 2-2h54c1.103 0 2 .897 2 2z"
            />
            <path
                fill={fill}
                d="m56 31c-1.654 0-3 1.346-3 3 0 .496.133.958.347 1.37l-1.347 1.539v-4.909c0-.552-.447-1-1-1h-6c-.553 0-1 .448-1 1v14c0 .552.447 1 1 1h6c.553 0 1-.448 1-1v-6.052l2.801-3.201c.368.161.772.253 1.199.253 1.654 0 3-1.346 3-3s-1.346-3-3-3zm-6 14h-4v-12h4v6.195l-2.753 3.146 1.506 1.317 1.247-1.425zm6-10c-.552 0-1-.449-1-1s.448-1 1-1 1 .449 1 1-.448 1-1 1z"
            />
            <path
                fill={fill}
                d="m20 5h-14c-.553 0-1 .448-1 1v14c0 .552.447 1 1 1h14c.553 0 1-.448 1-1v-14c0-.552-.447-1-1-1zm-1 14h-12v-12h12z"
            />
            <path
                fill={fill}
                d="m38 5h-14c-.553 0-1 .448-1 1v14c0 .552.447 1 1 1h14c.553 0 1-.448 1-1v-14c0-.552-.447-1-1-1zm-1 14h-12v-12h12z"
            />
            <path
                fill={fill}
                d="m13 23c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm1 13.91v-5.91h-2v5.91c-2.507-.424-4.486-2.403-4.91-4.91h1.91v-2h-1.91c.154-.909.51-1.749 1.025-2.471l1.178 1.178 1.414-1.414-1.178-1.178c.722-.515 1.562-.871 2.471-1.025v1.91h2v-1.91c.909.154 1.749.51 2.471 1.025l-1.178 1.178 1.414 1.414 1.178-1.178c.515.722.871 1.562 1.025 2.471h-1.91v2h1.91c-.424 2.507-2.403 4.486-4.91 4.91z"
            />
            <path
                fill={fill}
                d="m31 23c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm1 13.91v-5.91h-2v5.91c-2.507-.423-4.486-2.403-4.91-4.91h1.91v-2h-1.91c.154-.909.51-1.749 1.025-2.471l1.178 1.178 1.414-1.414-1.178-1.178c.722-.515 1.562-.871 2.471-1.025v1.91h2v-1.91c.909.154 1.749.51 2.471 1.025l-1.178 1.178 1.414 1.414 1.178-1.178c.515.722.871 1.562 1.025 2.471h-1.91v2h1.91c-.424 2.507-2.403 4.486-4.91 4.91z"
            />
            <path
                fill={fill}
                d="m12 41h-4c-.553 0-1 .448-1 1v4c0 .552.447 1 1 1h4c.553 0 1-.448 1-1v-4c0-.552-.447-1-1-1zm-1 4h-2v-2h2z"
            />
            <path
                fill={fill}
                d="m20 41h-4c-.553 0-1 .448-1 1v4c0 .552.447 1 1 1h4c.553 0 1-.448 1-1v-4c0-.552-.447-1-1-1zm-1 4h-2v-2h2z"
            />
            <path
                fill={fill}
                d="m28 41h-4c-.553 0-1 .448-1 1v4c0 .552.447 1 1 1h4c.553 0 1-.448 1-1v-4c0-.552-.447-1-1-1zm-1 4h-2v-2h2z"
            />
            <path
                fill={fill}
                d="m36 41h-4c-.553 0-1 .448-1 1v4c0 .552.447 1 1 1h4c.553 0 1-.448 1-1v-4c0-.552-.447-1-1-1zm-1 4h-2v-2h2z"
            />
            <path
                fill={fill}
                d="m49 28v-4c0-.552-.447-1-1-1h-6c-.553 0-1 .448-1 1v4c0 .552.447 1 1 1h6c.553 0 1-.448 1-1zm-2-1h-4v-2h4z"
            />
            <path
                fill={fill}
                d="m58 23h-6c-.553 0-1 .448-1 1v4c0 .552.447 1 1 1h6c.553 0 1-.448 1-1v-4c0-.552-.447-1-1-1zm-1 4h-4v-2h4z"
            />
            <path
                fill={fill}
                d="m58 5h-6c-.553 0-1 .448-1 1v14c0 .552.447 1 1 1h6c.553 0 1-.448 1-1v-14c0-.552-.447-1-1-1zm-1 14h-4v-12h4z"
            />
            <path
                fill={fill}
                d="m42 21h6c.553 0 1-.448 1-1v-14c0-.552-.447-1-1-1h-6c-.553 0-1 .448-1 1v14c0 .552.447 1 1 1zm1-14h4v12h-4z"
            />
        </g>
    </svg>
);

export default Bookings;
