import theme from '@Components/theme';
import DateFnsUtils from '@date-io/date-fns';
import Routes from '@Layouts/Routes';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import store from '@Store/index';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

const App = () => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <CssBaseline />
                <Router>
                    <Routes />
                </Router>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </Provider>
);

export default App;
