import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 81;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        toolbarComponent: theme.mixins.mainLayout,
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            ...theme.mixins.mainLayout,
        },
        mainContent: {
            margin: `${theme.spacing(2)}px 0 32px`,
        },
    }),
);

export default useStyles;
