import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    helperText: theme.mixins.helperText,
    endAdornmentText: {
        fontSize: 11,
        opacity: 0.5,
    },
}));

export default styles;
