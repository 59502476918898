/**
 * @function getListIndices
 * @description Find the index of first and last item in a page using offset, limit and totalcount.
 * @param {offset} number offset of the current page
 * @param {limit} number Limit value selected
 * @param {totalCount} number Totalcount
 * @return {number[]} array which contain the start index and end index of current page
 */
const getListIndices = (offset: number, limit: number, totalCount: number) => {
    const firstItem = offset * limit + 1;
    const lastItem = (offset + 1) * limit < totalCount ? (offset + 1) * limit : totalCount;
    return [firstItem, lastItem];
};

export default getListIndices;
